<template>
    <div class="spid-page">
        <dialog-select-provider
            v-if="dialogs.provider.show"
            v-model="dialogs.provider.show"

            :closable="false"

            @selected="goToReference"
        ></dialog-select-provider>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import dialogSelectProvider from '@/components/app-account/dialogs/dialog-select-provider'

export default {
    components: {
        dialogSelectProvider,
    },

    data() {
        return {
            referrer: null,

            dialogs: {
                provider: {
                    show: false,
                },
            },
        }
    },

    methods: {
        init(to, from) {
            this.referrer = to && to.params && to.params.$referrer
                ? to.params.$referrer
                : from

            this.checkCurrentSPID()
        },

        checkCurrentSPID() {
            switch (this.available_service_providers.length) {
                case 0: {
                    this.$store.dispatch('Logout').then(() => {
                        this.$router.push({ name: 'sign-in'})
                    })
                } break

                case 1: {
                    this.$store.dispatch('setCurrentSPID', this.available_service_providers[0].SPID).then(() => {
                        this.goToReference()
                    })
                } break

                default: {
                    this.dialogs.provider.show = true
                }
            }
        },

        goToReference() {
            this.$router.push(this.referrer)
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers'
        ]),
    },

    beforeRouteEnter(to, from, next) {
        next(route => { route.init( to, from ) })
    },
}
</script>

<style lang="scss"></style>