<template>
    <app-dialog class="dialog-select-provider"
        v-if="show"
        v-model="show"

        :loading="loading"
        :closable="closable"
        :max-width="maxWidth"
        :max-height="maxHeight"
    >
        <template #head>
            <h2 class="heading">Select Service Provider</h2>
        </template>

        <template #body>
            <app-error v-model="error"></app-error>

            <div v-if="are_logos_loaded" class="providers">
                <button
                    v-for="provider in sorted_providers"
                    :key="provider.UUID"

                    class="btn"

                    :class="{ active: provider.SPID == current_spid }"
                    @click="selectProvider(provider)"
                >
                    <img v-if="!spName(provider)" :src="provider_logos[provider.UUID]">
                    <p v-if="spName(provider)">{{ spName(provider) }}</p>
                </button>
            </div>
        </template>
    </app-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialog from '@/components/app-dialog'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:      { required: true                          },
        closable:   { type: Boolean,          default: true   },
        maxWidth:   { type: [Number, String], default: 750    },
        maxHeight:  { type: [Number, String], default: 'none' },
    },

    components: {
        appDialog,
        appError,
    },

    data() {
        return {
            provider_logos: {},
            are_logos_loaded: false,

            error: null,
            loading: false,
        }
    },

    created() {
        this.loadProviderLogos()
    },

    methods: {
        spName(sp) {
            if (this.provider_logos[sp.UUID].startsWith('data:'))  {
                return sp.Name.substring(0, 34)
            }
            return ''
        },

        loadProviderLogos() {
            this.provider_logos = {}

            this.are_logos_loaded = false
            this.loading = true

            const promises = []

            for (let i = 0, len = this.available_service_providers.length; i < len; i++) {
                const uuid = this.available_service_providers[i].UUID
                const src = this.available_service_providers[i].LogoWideURL

                promises.push(this.loadProviderLogo(uuid, src))
            }

            Promise.all(promises)
                .then(() => {
                    this.are_logos_loaded = true
                    this.loading = false
                })
                .catch(() => {
                    this.are_logos_loaded = true
                    this.loading = false
                })
        },

        loadProviderLogo(uuid, src) {
            return new Promise((resolve, reject) => {
                const img = new Image()

                img.onload = () => {
                    this.provider_logos[uuid] = src
                    resolve()
                }

                img.onerror = () => {
                    const size = 'wide'

                    import(`./img/${ size }.png`)
                        .then(src => {
                            this.provider_logos[uuid] = src.default
                            resolve()
                        })
                        .catch(error => {
                            this.provider_logos[uuid] = src
                            resolve()
                        })
                }

                img.src = src
            })
        },

        selectProvider(provider) {
            this.error = null
            this.loading = true

            this.$store.dispatch('setCurrentSPID', provider.SPID)
                .then(() => {
                    this.$store.dispatch('ResetServiceProviderState')
                        .then(() => {
                            /**
                             * this.$emit('selected', provider.SPID)
                             */

                            this.loading = false
                            this.show = false

                            this.$router.push({ name: 'main-dashboard', replace: true })
                        })
                })
                .catch(error => {
                    this.error = errMessage(error)
                    this.loading = false
                })
        },
    },

    computed: {
        ...mapGetters([
            'available_service_providers',
            'current_spid',
        ]),

        sorted_providers() {
            let providers = [ ...this.available_service_providers ]

            providers.sort((a, b) => a.SPID - b.SPID)

            return providers
        },

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.dialog-select-provider {
    z-index: $z-index-dialog-upmost;
    background-color: var(--color-dialog-over-secondary);

    .app-dialog-container {
        .app-dialog-body {
            padding: 80px 15px 95px;

            .providers {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                margin-top: 15px;

                .btn {
                    display: inline-block;

                    width: 100%;
                    max-width: 210px;
                    height: 50px;

                    margin: 15px;
                    padding: 0px;
                    padding-left: 8px;
                    padding-right: 8px;

                    background-color: var(--color-component-bg-primary);
                    border: 1px solid transparent;
                    border-radius: $btn-border-radius-secondary;
                    box-shadow: var(--box-shadow-primary);

                    vertical-align: middle;
                    text-align: center;

                    img {
                        display: inline-block;
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        margin-top: 9px;
                        max-height: 32px;
                    }

                    p {
                        font-size: 80%;
                    }

                    &.active {
                        border: 1px solid var(--color-active-service-provider);
                        box-shadow: none;
                        cursor: default;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
</style>